import { FC } from "react";
import { cn } from "@nextui-org/theme";

import socialUrls from "@/components/social-urls";
import Wrapper from "@/components/wrapper";
import { Github, LinkedIn, Medium, Twitter } from "@/icons";
import { accessibilityOutline } from "@/utils/misc";

const Social: FC = () => {
  return (
    <Wrapper alt="Social Links">
      <div className="flex justify-between">
        <a
          href={socialUrls.twitter}
          className={cn(
            "text-[2rem] transition-colors text-zinc-400 hover:text-zinc-50",
            accessibilityOutline,
          )}
          rel="noreferrer"
          target="_blank"
          aria-label="Twitter"
        >
          <span className="hidden">Twitter</span>
          <Twitter />
        </a>
        <a
          href={socialUrls.linkedin}
          className={cn(
            "text-[2rem] transition-colors text-zinc-400 hover:text-zinc-50",
            accessibilityOutline,
          )}
          rel="noreferrer"
          target="_blank"
          aria-label="LinkedIn"
        >
          <span className="hidden">LinkedIn</span>
          <LinkedIn />
        </a>
        <a
          href={socialUrls.medium}
          className={cn(
            "text-[2rem] transition-colors text-zinc-400 hover:text-zinc-50",
            accessibilityOutline,
          )}
          rel="noreferrer"
          target="_blank"
          aria-label="Medium"
        >
          <span className="hidden">Medium</span>
          <Medium />
        </a>
        <a
          href={socialUrls.github}
          className={cn(
            "text-[2rem] transition-colors text-zinc-400 hover:text-zinc-50",
            accessibilityOutline,
          )}
          rel="noreferrer"
          target="_blank"
          aria-label="Github"
        >
          <span className="hidden">Github</span>
          <Github />
        </a>
      </div>
    </Wrapper>
  );
};

export default Social;
