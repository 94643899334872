import { FC } from "react";
import Link from "next/link";
import Image, { StaticImageData } from "next/image";
import { cn } from "@nextui-org/theme";

import Pill from "@/components/pill";
import IconWrapper from "@/components/icon-wrapper";
import { TypeProjectJSON } from "@/utils/types";
import { accessibilityOutline } from "@/utils/misc";

interface Props {
  json: TypeProjectJSON | any;
  className?: string;
  isNew?: boolean;
  img: StaticImageData;
}

const Project: FC<Props> = ({ json, className, isNew, img }) => {
  return (
    <section aria-label={json.summary}>
      <Link
        href={json.link}
        className={cn(
          "grid active:scale-[.98] transition-all border border-zinc-50/10 hover:border-zinc-50/20 rounded-[2rem] pt-8 ps-8 gap-4 overflow-hidden",
          accessibilityOutline,
          className,
        )}
      >
        <div className="flex flex-wrap items-start justify-between">
          <IconWrapper src={json.icon} alt="App Icon" />
          {isNew && (
            <Pill className="text-blue-300 bg-blue-950/60 font-bold pt-1 pb-[0.1875rem]">
              NEW
            </Pill>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex gap-1">
            <Pill>{json.publishDate}</Pill>
            <Pill>{json.type}</Pill>
            {json.duration !== 0 ? (
              <Pill>{`${json.duration} mins`}</Pill>
            ) : null}
          </div>
          <h2 className="text-2xl font-bold leading-tight pe-8 mb-2">
            {json.title}
          </h2>
        </div>
        <Image
          width={378}
          height={378}
          src={img}
          quality={100}
          className="w-full h-auto"
          alt={json.title}
          placeholder="blur"
        />
      </Link>
    </section>
  );
};

export default Project;
