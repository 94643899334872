import(/* webpackMode: "eager", webpackExports: ["Tooltip"] */ "/opt/build/repo/node_modules/@nextui-org/tooltip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/public/design-system/cover.webp");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/public/filwsca/cover.webp");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/public/me.png");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/public/mobile-upload/cover.webp");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/public/player/cover.webp");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/public/reading-experience/cover.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/view-desktop.tsx");
